import { leaseService } from "../../foundations/leaseService";
import { useEffect, useState } from "react";
import { rentReviewService } from "../../foundations/rentReviewService";
import { ActionView } from "../../../models/views/components/actions/actionView";
import { getLeasesExpiring, getRentReviewDue, getRentReviewPendingInit, getRentReviewrentReview30to60Days, getRentReviewDisputeVal, getRentReviewTOEReceived, getRentReviewNoDVDeadline } from "./functions/getActionData";

export const actionViewService = {
    useGetAllActions: (searchTerm?: string, practiceId?: string, siteId?: string) => {
        try {

            if (searchTerm === undefined) { searchTerm = "" }

            const [mappedActions, setMappedActions] = useState<Array<ActionView>>([]);

            const leaseExpiryActions = leaseService
                .useGetLeaseExpireActions(searchTerm, true, practiceId, siteId);

            const rentReviewDueActions = rentReviewService
                .useGetRentReviewDueActions(searchTerm, true, practiceId, siteId);

            const rentReviewPendingInitActions = rentReviewService
                .useGetRentReviewPendingInitActions(searchTerm, true, practiceId, siteId);

            const rentReview30to60DaysActions = rentReviewService
                .useGetRentReviewStatus30to60DaysActions(searchTerm, true, practiceId, siteId);

            const rentReviewDisputeValActions = rentReviewService
                .useGetRentReviewDisputeResolutionActions(searchTerm, true, practiceId, siteId);

            const rentReviewTOEReceivedActions = rentReviewService
                .useGetRentReviewTOEReceivedActions(searchTerm, true, practiceId, siteId);

            const rentReviewNoDVDeadlineActions = rentReviewService
                .useGetRentReviewNoDVDeadlineActions(searchTerm, true, practiceId, siteId);

            useEffect(() => {
                let next: ActionView[] = []

                if (leaseExpiryActions.data) {
                    next = getLeasesExpiring(leaseExpiryActions.data, next);
                }
                if (rentReviewDueActions.data) {
                    next = getRentReviewDue(rentReviewDueActions.data, next);
                }

                if (rentReviewPendingInitActions.data) {
                    next = getRentReviewPendingInit(rentReviewPendingInitActions.data, next);
                }

                if (rentReview30to60DaysActions.data) {
                    next = getRentReviewrentReview30to60Days(rentReview30to60DaysActions.data, next);
                }

                if (rentReviewDisputeValActions.data) {
                    next = getRentReviewDisputeVal(rentReviewDisputeValActions.data, next);
                }

                if (rentReviewTOEReceivedActions.data) {
                    next = getRentReviewTOEReceived(rentReviewTOEReceivedActions.data, next);
                }

                if (rentReviewNoDVDeadlineActions.data) {
                    next = getRentReviewNoDVDeadline(rentReviewNoDVDeadlineActions.data, next);                    
                }

                setMappedActions(next.sort((a, b) => {
                    return b.due.getTime() - a.due.getTime() // Order by due date
                }));
            }, [
                leaseExpiryActions.data,
                rentReviewDueActions.data,
                rentReviewPendingInitActions.data,
                rentReview30to60DaysActions.data,
                rentReviewDisputeValActions.data,
                rentReviewTOEReceivedActions.data,
                rentReviewNoDVDeadlineActions.data
            ]);

            return {
                mappedActions,
                ...leaseExpiryActions,
                ...rentReviewDueActions,
                ...rentReviewPendingInitActions,
                ...rentReview30to60DaysActions,
                ...rentReviewDisputeValActions,
                ...rentReviewTOEReceivedActions,
                ...rentReviewNoDVDeadlineActions
            }

        } catch (err) {
            throw err;
        }
    },
}