import moment from "moment";

const dateDiff = (date: Date | undefined) => {
    return moment(date).diff(moment(), 'days', true)
}

const dateDiffReverse = (date: Date | undefined) => {
    return moment().diff(date, 'days', true)
}

export const getRagStatus = (date: Date) => {
    if (dateDiff(date) < 30) {
        return 'bg-danger';
    } else if ((dateDiff(date) >= 30 && dateDiff(date) < 60)) {
        return 'bg-warning';
    } else if (dateDiff(date) >= 60) {
        return 'bg-success';
    } else {
        return "bg-default"
    }
}

export const getRagStatusForState = (date: Date, days: number) => {
    if ((moment(date, "DD-MM-YYYY").add(days, 'days') < moment())) {
        return 'bg-danger';
    } else {
        return "bg-warning"
    }
}

export const getRagStatusForState30to60 = (date: Date) => {
    if (dateDiffReverse(date) > 60) {
        return 'bg-danger';
    } else {
        return "bg-warning"
    }
}

export const getRagStatus120Days = (date: Date) => {
    if (dateDiffReverse(date) > 120) {
        return 'bg-danger';
    } else {
        return "bg-warning"
    }
}

export const getRagStatusAmber = () => {
    return "bg-warning"    
}

