import { useMsal } from "@azure/msal-react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Guid } from "guid-typescript";
import LeaseBroker from "../../brokers/apiBroker.leases";
import { Lease } from "../../models/leases/lease";
import moment from "moment";


export const leaseService = {
    useCreateLease: () => {
        const leaseBroker = new LeaseBroker();
        const queryClient = useQueryClient();
        const msal = useMsal();

        return useMutation((lease: Lease) => {
            const date = new Date();
            lease.createdDate = lease.updatedDate = date;
            lease.createdByUser = lease.updatedByUser = msal.accounts[0].username;

            return leaseBroker.PostLeaseAsync(lease);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("LeaseGetAll");
                    queryClient.invalidateQueries(["LeaseGetById", { id: data.id }]);
                }
            });
    },

    useGetAllLeases: (query: string, enabled: boolean = true) => {
        const leaseBroker = new LeaseBroker();

        return useQuery(
            ["LeaseGetAll", { query: query }],
            () => leaseBroker.GetAllLeaseAsync(query),
            {
                staleTime: Infinity,
                enabled: enabled
            });
    },

    useGetLeaseById: (id: Guid, enabled: boolean = true) => {
        const leaseBroker = new LeaseBroker();

        return useQuery(
            ["LeaseGetById", { id: id }],
            () => leaseBroker.GetLeaseByIdAsync(id),
            {
                staleTime: Infinity,
                enabled: enabled
            });
    },

    useGetLeaseExpireActions: (searchTerm: string, enabled: boolean = true, practiceId?: string, siteId?: string) =>
    {
        let queryDate = moment().add(90, 'days').format("YYYY-MM-DD");
        let ignoreNotional = 'Notional'
       
        let query = `?$select=id,siteId,leaseEndDate&$expand=site($select=id,siteName)`
            + `&$expand=site($expand=practice($select=id, practiceName, practiceCode))`
            + `&$expand=site($expand=practice($expand=borough($select=id,name)))`
            + `&$orderby=leaseEndDate, site/practice/practiceName`
            + `&$filter=isActive eq true and leaseEndDate lt ${queryDate} `
            + `and TenancyType/name ne '${ignoreNotional}'`;

        if (practiceId) {
            query = query + ` and site/practiceId eq ${practiceId}`;
        }

        if (siteId) {
            query = query + ` and site/id eq ${siteId}`;
        }

        if (searchTerm) {
            query = query + ` and (contains(site/practice/practiceName,'${searchTerm}')`
                + ` or contains(site/practice/practiceCode,'${searchTerm}')`
                + ` or contains(site/practice/borough/name,'${searchTerm}'))`
        }

        const leaseBroker = new LeaseBroker();

        return useQuery(
            ["LeaseGetAll", { query: query }],
            () => leaseBroker.GetAllLeaseAsync(query),
            {
                staleTime: Infinity,
                enabled: enabled
            });
    },


    useGetLeaseBySiteId: (siteId: Guid) => {
        const leaseBroker = new LeaseBroker();

        return useQuery(
            ["LeaseGetById", { siteId: siteId }],
            () => leaseBroker.GetLeaseByIdAsync(siteId),
            {
                staleTime: Infinity
            });
    },

    useUpdateLease: () => {
        const leaseBroker = new LeaseBroker();
        const queryClient = useQueryClient();
        const msal = useMsal();

        return useMutation((lease: Lease) => {
            const date = new Date();
            lease.updatedDate = date;
            lease.updatedByUser = msal.accounts[0].username;

            return leaseBroker.PutLeaseAsync(lease);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("LeaseGetAll");
                    queryClient.invalidateQueries(["LeaseGetById", { id: data.id }]);
                    queryClient.invalidateQueries(["LeaseDeleteById", { id: data.id }]);
                }
            });
    },

    useDeleteLease: () => {
        const leaseBroker = new LeaseBroker();
        const queryClient = useQueryClient();

        return useMutation((id: Guid) => {
            return leaseBroker.DeleteLeaseByIdAsync(id);
        },
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries("LeaseGetAll");
                    queryClient.invalidateQueries(["LeaseGetById", { id: data.id }]);
                }
            });
    },
}
