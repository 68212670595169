import React, { FunctionComponent } from "react";
import TableBaseRow from "../bases/components/Table/TableBase.Row";
import TableBaseData from "../bases/components/Table/TableBase.Data";
import moment from "moment";
import { Badge } from "react-bootstrap";
import ButtonBase from "../bases/buttons/ButtonBase";
import { Link } from "react-router-dom";
import { ActionView } from "../../models/views/components/actions/actionView";

type ActionsRowViewProps = {
    leaseAction: ActionView;
    showGoTo?: boolean;
}

const ActionsRowView: FunctionComponent<ActionsRowViewProps> = (props) => {
    const {
        leaseAction,
        showGoTo
    } = props;

    return (
        <TableBaseRow >
            <TableBaseData key={leaseAction.id?.toString() + leaseAction.type?.toString()}>
                {leaseAction.practiceName} ({leaseAction.practiceCode}) 
            </TableBaseData>
            <TableBaseData classes="">
                {leaseAction.borough}
            </TableBaseData>
            <TableBaseData classes="">
                {leaseAction.siteName}
            </TableBaseData>
            <TableBaseData classes="">
                {leaseAction.name}
            </TableBaseData>
            <TableBaseData classes="text-center">
                <Badge className={leaseAction.ragStatus} style={{ padding: "13px" }} >
                    {moment(leaseAction.due).format("Do-MMM-yyyy")}
                    <br />
                    &nbsp;({moment().to(moment(leaseAction.due))})
                </Badge>
            </TableBaseData>
            {showGoTo &&
                <TableBaseData classes="text-end" >
                    <>
                        <Link to={'/site/' + leaseAction.practiceId + '/' + leaseAction.siteId?.toString() + '/leases'}>
                            <ButtonBase onClick={() => { }} view>See Details</ButtonBase>
                        </Link>
                    </>
                </TableBaseData>
            }
        </TableBaseRow>
    );
}

export default ActionsRowView;