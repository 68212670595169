import { LeaseActions } from "../../../../models/leaseActions/leaseActions";
import { RentReviewActions } from "../../../../models/rentReviewActions/rentReviewActions";
import { ActionView } from "../../../../models/views/components/actions/actionView";
import { getRagStatus, getRagStatusForState, getRagStatusForState30to60, getRagStatus120Days, getRagStatusAmber } from "./getRAGColour";

export const getLeasesExpiring = (leaseExpiryActions: any, next: ActionView[]) => {

    const leaseActions = leaseExpiryActions.map((lease: LeaseActions) =>
        new ActionView(
            lease.id,
            lease.leaseEndDate,
            "Lease",
            "Lease Expiry Date",
            getRagStatus(lease.leaseEndDate),
            lease.site?.practice.id,
            lease.site?.practice.practiceName,
            lease.site?.practice.practiceCode,
            lease.site?.id,
            lease.site?.siteName,
            lease.site?.practice.borough.name,
        ));

    return [...next, ...leaseActions]
}

export const getRentReviewDue = (rentReviewDueActions: any, next: ActionView[]) => {
    const RentRevActions = rentReviewDueActions.map((rentReview: RentReviewActions) =>
        new ActionView(
            rentReview.id,
            rentReview.rentReviewDueDate,
            "RentReview",
            "Rent Review Due",
            getRagStatus(rentReview.rentReviewDueDate),
            rentReview.lease.site?.practice.id,
            rentReview.lease.site?.practice.practiceName,
            rentReview.lease.site?.practice.practiceCode,
            rentReview.lease.site?.id,
            rentReview.lease.site?.siteName,
            rentReview.lease.site?.practice.borough.name
        ));

    return [...next, ...RentRevActions]
}

export const getRentReviewPendingInit = (rentReviewPendingInitActions: any, next: ActionView[]) => {
    const rentReviewPendingInit = rentReviewPendingInitActions.map((rentReviewPendingInit: RentReviewActions) =>
        new ActionView(
            rentReviewPendingInit.id,
            rentReviewPendingInit.reviewStageChangedDate,
            "RentReviewPendingInit",
            "Rent Review Pending Initiation",
            getRagStatusForState(rentReviewPendingInit.reviewStageChangedDate,30),
            rentReviewPendingInit.lease.site?.practice.id,
            rentReviewPendingInit.lease.site?.practice.practiceName,
            rentReviewPendingInit.lease.site?.practice.practiceCode,
            rentReviewPendingInit.lease.site?.id,
            rentReviewPendingInit.lease.site?.siteName,
            rentReviewPendingInit.lease.site?.practice.borough.name
        ));

    return [...next, ...rentReviewPendingInit]
}

export const getRentReviewrentReview30to60Days = (rentReview30to60DaysActions: any, next: ActionView[]) => {
    const rentReview30to60Days = rentReview30to60DaysActions.map((rentReview30to60Days: RentReviewActions) =>
        new ActionView(
            rentReview30to60Days.id,
            rentReview30to60Days.reviewStageChangedDate,
            "RentReview30to60Day",
            'Rent Review ' + rentReview30to60Days.reviewStage.name,
            getRagStatusForState30to60(rentReview30to60Days.reviewStageChangedDate),
            rentReview30to60Days.lease.site?.practice.id,
            rentReview30to60Days.lease.site?.practice.practiceName,
            rentReview30to60Days.lease.site?.practice.practiceCode,
            rentReview30to60Days.lease.site?.id,
            rentReview30to60Days.lease.site?.siteName,
            rentReview30to60Days.lease.site?.practice.borough.name
        ));

    return [...next, ...rentReview30to60Days]
}

export const getRentReviewDisputeVal = (rentReviewDisputeValActions: any, next: ActionView[]) => {
    const rentReviewDisputeVal = rentReviewDisputeValActions.map((rentReviewDisputeVal: RentReviewActions) =>
        new ActionView(
            rentReviewDisputeVal.id,
            rentReviewDisputeVal.reviewStageChangedDate,
            "RentReviewDispute",
            "Rent Review Dispute",
            getRagStatusForState(rentReviewDisputeVal.reviewStageChangedDate, 56),
            rentReviewDisputeVal.lease.site?.practice.id,
            rentReviewDisputeVal.lease.site?.practice.practiceName,
            rentReviewDisputeVal.lease.site?.practice.practiceCode,
            rentReviewDisputeVal.lease.site?.id,
            rentReviewDisputeVal.lease.site?.siteName,
            rentReviewDisputeVal.lease.site?.practice.borough.name
        ));

    return [...next, ...rentReviewDisputeVal]
}

export const getRentReviewTOEReceived = (getRentReviewTOEReceivedActions: any, next: ActionView[]) => {

    const rentReviewTOEReceived = getRentReviewTOEReceivedActions.map((rentReviewTOEReceived: RentReviewActions) =>
        new ActionView(
            rentReviewTOEReceived.id,
            rentReviewTOEReceived.districtValuerDeadlineDate,
            "RentReviewTOEReceived",
            "Rent Review TOE Received/District Valuation Deadline Date",
            getRagStatus120Days(rentReviewTOEReceived.districtValuerDeadlineDate),
            rentReviewTOEReceived.lease.site?.practice.id,
            rentReviewTOEReceived.lease.site?.practice.practiceName,
            rentReviewTOEReceived.lease.site?.practice.practiceCode,
            rentReviewTOEReceived.lease.site?.id,
            rentReviewTOEReceived.lease.site?.siteName,
            rentReviewTOEReceived.lease.site?.practice.borough.name
        ));

    return [...next, ...rentReviewTOEReceived]
}

export const getRentReviewNoDVDeadline = (rentReviewDVDeadlineActions: any, next: ActionView[]) => {
    const rentReviewDVDeadline = rentReviewDVDeadlineActions.map((rentReviewDVDeadline: RentReviewActions) =>
        new ActionView(
            rentReviewDVDeadline.id,
            rentReviewDVDeadline.reviewStageChangedDate,
            "RentReviewDVDeadline",
            "Rent Review TOE Received with no District Valuation Deadline Date",
            getRagStatusAmber(),
            rentReviewDVDeadline.lease.site?.practice.id,
            rentReviewDVDeadline.lease.site?.practice.practiceName,
            rentReviewDVDeadline.lease.site?.practice.practiceCode,
            rentReviewDVDeadline.lease.site?.id,
            rentReviewDVDeadline.lease.site?.siteName,
            rentReviewDVDeadline.lease.site?.practice.borough.name
        ));

    return [...next, ...rentReviewDVDeadline]
}